<template>
  <div class="home">
    <div :class="type ? 'login' : 'register'"></div>
    <span>© 2024 MarbleX. All rights reserved. </span>
    <div class="block">
      <div class="ball" :style="style.ball"></div>
      <div class="formBlock" :style="style.formBlock">
        <img alt="logo" src="../assets/logo.png" />
        <h2 :class="{ enlarge: height < 700 }">{{ blockData.title }}</h2>
        <p v-html="blockData.subtitle"></p>
        <div :class="['form', { enlarge: height < 700 }]" novalidate>
          <div
            class="form-group"
            v-for="(input, i) in blockData.input"
            :key="i"
          >
            <label
              :for="input.placeholder"
              :class="{
                active: inputFocus == i || fromData[input.input].length > 0,
              }"
              >{{ input.placeholder }}</label
            >
            <input
              autocomplete="off"
              @keyup="setValid"
              :class="{ active: fromData[input.input].length > 0 }"
              @blur="inputFocus = null"
              @focus="inputFocus = i"
              :type="input.type"
              :id="input.placeholder"
              :name="input.placeholder"
              minlength="1"
              :maxlength="input.maxlength"
              :pattern="
                input.placeholder == 'mail'
                  ? '/^[^@\s]+@[^@\s]+\.[^@\s]+$/'
                  : input.pattern
              "
              v-model="fromData[input.input]"
              :value="fromData[input.input]"
              required
            />
            <div v-if="valid[input.placeholder]" class="error">
              {{ valid[input.placeholder] }}
            </div>
          </div>
          <div class="buttonBlock">
            <button
              :style="{ maxWidth: msg?.text?.length > 0 ? '75px' : '135px' }"
              class="button"
              :disabled="
                loading || (msg?.text?.length > 0 && msg?.error == '0')
              "
              @click="getAPI"
            >
              {{ msg?.text?.length > 0 ? "SENT" : blockData.button }}
            </button>
            <LoaderComponent v-if="loading" />
            <p
              v-if="msg?.text?.length > 0"
              :style="{
                color: msg?.error != '0' ? 'red' : '#50c4ff',
                fontSize: '12px',
              }"
              v-html="msg.text"
            ></p>
          </div>
        </div>
        <p>
          {{ blockData.linkText }}
          <strong @click="changeType">{{ blockData.link }}</strong>
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import axios from "axios";
import { ref, computed, watch, onMounted, nextTick } from "vue";
import { useWindowSize } from "@vueuse/core";
import { useRoute } from "vue-router";
import LoaderComponent from "@/components/LoaderComponent.vue";

const route = useRoute();
const msg = ref({ error: "" as string, text: "" as string });
const valid = ref({} as { [key: string]: any });
const fromData = ref({
  username: "" as string,
  password: "" as string,
  name: "" as string,
  email: "" as string,
  company: "" as string,
  input: "" as string,
} as { [key: string]: string }).value;
const { width, height } = useWindowSize();
const isMobile = ref(false);
const inputFocus: any = ref(null);
const type = ref(true);
const loading = ref(false);
const signData = ref({
  title: "Get Your Game On!",
  button: "SIGN ME UP",
  subtitle:
    "Reach out today and let's start rolling! Fill out the form below to request your demo access.!",
  linkText: "Already have an account?",
  link: "Login here!",
  input: [
    {
      type: "text",
      placeholder: "Name",
      input: "name",
      maxlength: 50,
      pattern: "^.{1,50}$",
    },
    {
      type: "email",
      placeholder: "Email",
      input: "email",
    },
    {
      type: "text",
      placeholder: "Company",
      input: "company",
      maxlength: 50,
      pattern: "^.{1,50}$",
    },
  ],
});
const enterData = ref({
  title: "Embark on a Marbelous Adventure!",
  button: "ENTER DEMO",
  subtitle:
    "Your revolutionary racing experience starts here!<br />Enter the credentials to access the games.",
  linkText: "Don’t have a demo account?",
  link: "Request it!",
  input: [
    {
      type: "text",
      placeholder: "Login",
      input: "username",
      maxlength: 8,
      pattern: "[A-Za-z0-9]{8}",
    },
    {
      type: "password",
      placeholder: "Password",
      input: "password",
      maxlength: 8,
      pattern: "[A-Za-z0-9]{8}",
    },
  ],
});
const blockData = computed(() => {
  return !type.value ? signData.value : enterData.value;
});
const style = computed(() => {
  let styleData = {
    formBlock: ballStyle.value.formBlock,
    ball: {
      transform: ballStyle.value.transform,
      clipPath: ballStyle.value.clipPath,
      backgroundSize: ballStyle.value.backgroundSize,
    },
  };
  return styleData;
});

const ballStyle = computed(() => {
  if (!isMobile.value) {
    const translateXValue = width.value / 2.1;
    const translateXValueSmall = width.value / 3.8;
    return {
      formBlock: {
        right: type.value ? "10%" : "auto",
        left: !type.value ? "12%" : "auto",
      },
      transform: type.value
        ? `translateX(${
            width.value < 1300 ? translateXValueSmall : translateXValue
          }px) rotate(-40deg)`
        : `translateX(-${
            width.value < 1300 ? translateXValueSmall : translateXValue
          }px) rotate(95deg)`,
      clipPath: "",
    };
  } else {
    const translateYValue = height.value / 2.5;
    return {
      backgroundSize: width.value > 700 ? "contain" : "cover",
      formBlock: {
        right: 0,
        left: 0,
        margin: "auto",
        top: type.value ? `5%` : "auto",
        bottom: !type.value ? `10%` : "auto",
      },
      transform: type.value
        ? `translate(20%, -${translateYValue + 50}px) rotate(0deg)`
        : `translate(20%, ${translateYValue}px) rotate(0deg)`,
      // clipPath: type.value
      //   ? "circle(80% at 50% 25%)"
      //   : "circle(80% at 50% 75%)",
    };
  }
});

const getVh = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};
watch(width, (newWidth, oldWidth) => {
  isMobile.value = newWidth < 900;
  getVh();
});
watch(height, (newHeight, oldHeight) => {
  getVh();
});
watch(route, (newHash, oldHash) => {
  type.value = newHash.hash == "#login" || newHash.fullPath == "/";
});
onMounted(() => {
  isMobile.value = width.value < 900;
  type.value = route.hash == "#login" || route.fullPath == "/";
  getVh();
});
function changeType() {
  Object.entries(fromData).forEach(([key, value]) => {
    fromData[key] = "";
  });
  const input = document.querySelectorAll("input");
  valid.value = {};
  msg.value.text = "";
  input.forEach((el) => {
    el.classList.remove("invalid");
  });
  nextTick(() => {
    type.value = !type.value;
  });
}
function setValid(e?: any) {
  if (e) {
    const { patternMismatch, valueMissing } = e.srcElement.validity;
    if (valueMissing) {
      e.srcElement.classList.add("invalid");
      valid.value[e.srcElement.name] = "Field should be filled.";
      return;
    }
    console.log(patternMismatch, valueMissing);
    e.srcElement.classList.remove("invalid");
    valid.value[e.srcElement.name] = "";
    // if (patternMismatch) {
    //   e.srcElement.classList.add("invalid");
    //   valid.value[e.srcElement.name] = "Invalid format.";
    //   return;
    // }
  } else {
    const input = document.querySelectorAll("input");
    valid.value = {};
    input.forEach((el) => {
      console.log(el.validity);
      if (el.validity.valueMissing) {
        el.classList.add("invalid");
        valid.value[el.name] = "Field should be filled.";
        return;
      }
      if (el.validity.patternMismatch || el.validity.typeMismatch) {
        el.classList.add("invalid");
        valid.value[el.name] = "Invalid format.";
        return;
      }
      el.classList.remove("invalid");
    });
  }
}
function getAPI() {
  setValid();
  if (Object.keys(valid.value).length != 0) return;
  loading.value = true;
  const { username, password, name, email, company } = fromData;
  const api = type.value ? "Login" : "Register";
  const LoginData = {
    username,
    password,
  };
  const RegisterData = {
    name,
    email,
    company,
  };
  const params = type.value ? LoginData : RegisterData;
  axios
    .post(`https://demo.janusapi.com/api/MarbleX/${api}`, params)
    .then((res: any) => {
      loading.value = false;
      // console.log("<getAPI>", res.data);
      if (res?.data?.errorCode == "0") {
        setTimeout(() => {
          res?.data?.launchGameUrl &&
            window.open(res?.data?.launchGameUrl, "_blank");
        });
        if (!type.value) {
          msg.value = {
            error: res?.data?.errorCode,
            text: `Thank you ${name}! We will get in touch with you right away!`,
          };
        }
      } else {
        if (res?.data?.errorCode == "1") {
          msg.value = {
            error: res?.data?.errorCode,
            text: "This email has already been used; please confirm or wait for our approval.",
          };
        } else {
          msg.value = {
            error: res?.data?.errorCode,
            text: res?.data?.message,
          };
        }
      }
    })
    .catch((error: any) => {
      loading.value = false;
      console.log("<getAPI error>", error);
    });
}
</script>
<style lang="scss">
.home {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  .login {
    width: 100%;
    background: url("../assets/Loginw.png") no-repeat center left fixed;
    // transition: all 0.2s;
    background-size: cover;
    @media screen and (max-width: 900px) {
      background: url("../assets/Login.png") no-repeat bottom fixed;
      background-size: contain;
    }
  }
  .register {
    // transition: all 0.2s;
    width: 100%;
    background: url("../assets/Requestw.png") no-repeat center right fixed;
    background-size: cover;
    @media screen and (max-width: 900px) {
      background: url("../assets/Request.png") no-repeat top fixed;
      background-size: contain;
    }
  }
  span {
    color: #b4bdc5;
    font-size: 12px;
    position: absolute;
    bottom: 1.5%;
    text-align: center;
    display: block;
    width: 100%;
    z-index: 2;
  }
  p {
    color: #b4bdc5;
    max-width: 335px;
    width: 100%;
    line-height: 22.5px;
    @media screen and (max-width: 550px) {
      font-size: 12px;
      line-height: 15px;
    }
    strong {
      color: #07acf0;
      cursor: pointer;
      font-weight: 600;
      transition: all 0.3s;
      padding-left: 5px;
      &:hover {
        transition: all 0.3s;
        color: #50c4ff;
      }
    }
  }
  .form {
    max-width: 300px;
    width: 100%;
    margin-bottom: 8%;
    &.enlarge {
      max-width: 200px;
      margin-bottom: 5%;
    }
    @media screen and (max-width: 550px) {
      margin-bottom: 6%;
      max-width: 280px;
    }
    @media screen and (max-width: 380px) {
      margin-bottom: 5%;
    }
    .buttonBlock {
      display: flex;
      align-items: center;
      margin-top: 10%;
      @media screen and (max-width: 550px) {
        margin-top: 8%;
      }
      @media screen and (max-width: 380px) {
        margin-top: 5%;
      }
    }
    .button {
      text-align: center;
      margin-right: 10px;
      width: 100%;
      max-width: 135px;
      line-height: 40px;
      background: #07acf0;
      border-radius: 50px;
      transition: all 0.3s;
      border: 0;
      color: #000;
      font-size: 1rem;
      overflow: hidden;
      border: 1px solid #07acf0;
      position: relative;
      cursor: pointer;
      &:hover {
        background: transparent;
        color: #fff;
        box-shadow: inset 0 -3.25em 0 0 #002060;
      }
      &:active {
        transform: scale(0.95);
      }
      &:disabled {
        border-color: #396286;
        background: #396286;
        color: #7f94a2;
        cursor: not-allowed;
        box-shadow: none;
      }
    }
    .form-group {
      margin-top: 10%;
      position: relative;
      .error {
        color: red;
        font-size: 12px;
        position: absolute;
      }
      @media screen and (max-width: 550px) {
        margin-top: 8%;
      }
      @media screen and (max-width: 380px) {
        margin-top: 5%;
      }
    }
    label {
      font-size: 12px;
      font-weight: 400;
      transform: translateY(16px);
      transition: all 0.3s;
      cursor: text;
      z-index: -1;
      left: 5%;
      position: absolute;
      &.active {
        transform: translateY(5px);
        font-size: 10px;
        opacity: 0.7;
      }
    }
    input:not([type="submit"]) {
      opacity: 0.6;
      width: 100%;
      padding: 22px 15px 7px;
      border-radius: 4px;
      color: #fff;
      border: 1px solid;
      font-size: 13px;
      transition: all 0.3s;
      &:focus,
      &:hover,
      &.active {
        transition: all 0.3s;
        opacity: 1;
      }
      &::placeholder {
        color: #fff;
      }
      &.invalid {
        border-color: red;
      }
      &:c {
        display: none;
      }
    }
  }
  .ballImg {
    transition: all 0.3s;
    position: absolute;
    z-index: 1;
    width: 80%;
    @media screen and (max-width: 900px) {
      width: 200%;
    }
  }
  .ball {
    transition: all 0.8s;
    background: url("../assets/ball.png") no-repeat center center;
    position: absolute;
    width: 100%;
    background-size: cover;
    height: 0;
    padding-bottom: 100%;
    z-index: 1;
    @media screen and (max-width: 900px) {
      height: 160%;
      background-size: 100% 100%;
      width: 160%;
    }
  }
  .block {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
    .formBlock {
      z-index: 2;
      position: absolute;
      width: 80%;
      right: 0;
      max-width: 400px;
      img {
        width: 100px;
      }
      h2 {
        font-size: 2rem;
        font-family: "korataki_book", sans-serif;
        line-height: 3rem;
        padding: 5% 0;
        text-transform: uppercase;
        &.enlarge {
          font-size: 20px;
          line-height: 30px;
          padding: 3% 0;
        }
        @media screen and (max-width: 550px) {
          font-size: 20px;
          line-height: 30px;
          padding: 3% 0;
        }
        @media screen and (max-width: 380px) {
          line-height: 25px;
          font-size: 16px;
          padding: 3% 0;
        }
      }
    }
  }
}
</style>
