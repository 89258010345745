<template>
  <router-view />
</template>

<style lang="scss">
@font-face {
  font-family: "korataki_book";
  src: url("./assets/korataki_book.ttf") format("truetype");
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@500&display=swap");
body {
  width: 100%;
  overflow: hidden;
  background: #131522;
  text-align: left;
  color: #fff;
}
* {
  font-size: 0.9rem;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  list-style: none;
  padding: 0;
}
input {
  all: inherit;
  margin: 0;
}
html {
  height: 100%;
  width: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
$base: #3f4c61;
//scrollbar
::-webkit-scrollbar {
  border-radius: 50px;
  width: 2px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba($base, 0.5);
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba($base, 0.8);
  transition: all 0.3s;
  &:hover {
    transition: all 0.3s;
    background: $base;
  }
}
</style>
